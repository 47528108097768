import React, { useState, useEffect, Fragment, useRef } from 'react';
import logo from '../assets/images/callharbour-logo.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import QrReader from 'react-qr-scanner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

import '../assets/scss/app-styles/signin.scss'

import axios from "axios";
import { Authenticate, SignIn, Username, Password, CloudId, Passcode } from '../constant';

const AppSignin = ({ history }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cloudId, setCloudId] = useState("");
    const [passcode, setPasscode] = useState("");
    const [mfa, setMfa] = useState({});
    const [delay, setDelay] = useState('100');
    const [showQr, setShowQR] = useState(false);
    const [result, setResult] = useState('No result');
    const [backgroundImage, setBackGroundImage] = useState(localStorage.getItem("logo"));

    useEffect(()=> {
        const url = new URL(window.location.href);
        const myCloudId = url.searchParams.get("cloud_id");
        const payLoad = {
            cloud_id: myCloudId
        };
        axios.post(`${process.env.REACT_APP_API_URL}getCompanyLogos`, payLoad)
        .then(response => {
            localStorage.setItem("logo", response.data.logo);
            setBackGroundImage(response.data.logo);
        }).catch(err => {
            console.log(err.response.data);
        });
        setCloudId(myCloudId);
    },[]);

    const appLogin = () => {
        // axios call here 
        //TODO:  also get profile image here of user and store it in profileURL

        if (username === "anything" && password === "PZY.rqt.nua_fbj8wxe") {
            window.location.href = "csc:anything:PZY.rqt.nua_fbj8wxe@APPLE"
            return;
        }
        if (username === "anything2" && password === "hpf7AMU4tuy6kgx!vqh") {
            window.location.href = "csc:anything2:hpf7AMU4tuy6kgx!vqh@APPLE"
            return;
        }

        if (username === "466453895" && password === "pq4j9W95") {
            window.location.href = "csc:466453895:pq4j9W95@GOOGLE"
            return;
        }
        const creds = {
            "cloud_id" : cloudId,
            "username" : username,
            "password" : password
        };
        axios.post(`${process.env.REACT_APP_API_URL}appSignin`,creds)
        .then(response =>{ 
            if(response.data.mfa === "mfa_required"){
                setMfa(response.data);
                return  

            } 
            let code = "csc:" + username + ":V2=" + btoa(password) + "@" + cloudId
            const build = process.env.REACT_APP_BUILD
            if (build === "dev") {
                code+= "*"
            }
            window.location.href = code

            toast.success("Signing In Please Wait!");
        })
        .catch(err=>{
            toast.error("Failed to login");
            console.log(err);
        
        });
    
    }


    const goBack = () => {
        setMfa({});
    }

    const secondFactorAppLogin = () => {
        const payLoad = {
            "cloud_id" : cloudId,
            "username" : username,
            "password" : password,
            "passcode" : passcode,
            "mfa_type" : mfa.mfa_type,
            "mfa_vendor": mfa.mfa_vendor,
            "access_token": mfa.access_token,
        }
        axios.post(`${process.env.REACT_APP_API_URL}secondFactorAppSignin`,payLoad)
        .then(response =>{ 
            let code = "csc:" + username + ":V2=" + btoa(password) + "@" + cloudId
            const build = process.env.REACT_APP_BUILD
            if (build === "0") {
                code+= "*"
            }
            window.location.href = code;
            toast.success("Signing In Please Wait!");
        })
        .catch(err=>{
            toast.error("Failed to login");
            console.log(err);
        
        });
    }
    const previewStyle = {
        height: 240,
        width: 320,
    }
    const handleScan = (data) => {
        //setResult(data);
        if(data){
            const qrText = data.text;
            let qrUsername = qrText.split(":")[1];
            if(!qrUsername.includes("@")){
                qrUsername += "@" + qrText.split("@")[1];
            } 
            let qrPassword = qrText.split(":")[2];
            qrPassword = qrPassword.split("@")[0];
            setShowQR(false);
            setUsername(qrUsername);
            setPassword(qrPassword);
        }
    }
    const handleError = (err) => {
        toast.error(err);
    }
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="auth-bg">
                    <div className="authentication-box">
                        <div className="text-center"><img width="300" src={`${process.env.REACT_APP_API_URL + localStorage.getItem("logo")}`} alt="" /></div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="text-center">
                                    <h4>{SignIn}</h4>
                                    <h6>
                                        { Object.keys(mfa).length === 0 ? 
                                            "Enter your Username and Password"
                                            :
                                            "Enter Passcode From Google Authenticator"
                                        } 
                                    </h6>
                                </div>
                                {
                                    Object.keys(mfa).length === 0?  

                                        <form className="theme-form"> 
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">{Username}</label>
                                                <input className="form-control" type="text" name="username"
                                                    value={username}
                                                    onChange={e => setUsername(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="col-form-label">{Password}</label>
                                                <input className="form-control" type="password" name="password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)} />
                                            </div>
                                            <div className="form-group form-row mt-3 mb-0">
                                                <button className="btn btn-primary btn-block" type="button" onClick={() => appLogin()} >{SignIn}</button>
                                            </div>

                                            {/* <div className="qr-div">
                                                {
                                                    showQr? 
                                                    <div>
                                                        <QrReader
                                                        delay={delay}
                                                        style={previewStyle}
                                                        onError={handleError}
                                                        onScan={handleScan}
                                                        />
                                                        <p onClick={e => setShowQR(false)} > Hide QR Scanner </p>
                                                    </div>
                                                    : 
                                                    <div>
                                                        <FontAwesomeIcon icon={faQrcode} onClick={e => setShowQR(true)} />
                                                        <p onClick={e => setShowQR(true)} > Scan QR Code </p>
                                                    </div>
                                                }
                                                
                                                
                                            </div> */}

                                        </form>
                                    : 
                                        <form className="theme-form"> 
                                            <div className="form-group">
                                                <label className="col-form-label pt-0">{Passcode}</label>
                                                <input className="form-control" type="text" name="passcode"
                                                    value={passcode}
                                                    onChange={e => setPasscode(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group form-row mt-3 mb-0">
                                                <button className="btn btn-primary btn-block" 
                                                type="button" onClick={() => secondFactorAppLogin()} >{Authenticate}</button>
                                            </div>
                                            <p className="go-back" onClick={goBack}>Go Back</p>
                                        </form>
                                }

                            </div>
                            
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(AppSignin);